<template>
  <div class="calendar-component">
    <div class="calendar-header">
      <div class="fx-row weekday-label">
        <div v-for="(item, index) in '日一二三四五六'" :key="index" class="fx-1" :style="{ color: index === 0 || index === 6 ? '#ff9600' : '#262626' }">
          {{ item }}
        </div>
      </div>
    </div>
    <div class="calendar-body" id="calendar-scroll-box">
      <div v-for="(month, index) in monthList" :key="index">
        <div :id="'cal-m-' + month.id" class="month-label">
          {{ month.text }}
        </div>
        <div class="days">
          <div class="bg-large-number">{{ month.m }}</div>
          <div v-for="(item, daysIndex) in month.days" :key="daysIndex" class="day" :class="[
              item.k === range0 ? 'i-open' : '',
              item.k === range1 ? 'i-close' : '',
              item.k > range0 && item.k < range1 ? 'include' : '',
            ]">
            <div v-if="!item.blank" @click="onTapDay(item, index, daysIndex)" class="date" :class="[
                item.k === v0key || item.k === v1key ? 'active' : '',
                item.disabled ? 'date-disabled' : 'date-sp__' + item.sp,
              ]">
              <div v-if="type === 'RT' && item.k === v0key" class="date-head">
                入住
              </div>
              <div v-else-if="type === 'RT' && item.k === v1key" class="date-head">
                退房
              </div>
              <div v-else-if="item.hdt" :class="[item.hdc]" class="date-head">
                {{ item.hdt }}
              </div>
              <div class="date-bd">{{ item.d }}</div>
              <div v-if="priceMap[item.k]" class="date-foot" :class="[priceMap[item.k].t === 1 ? 'low-price' : '']">
                ¥{{ priceMap[item.k].v }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 确定 -->
    <div class="fn-btns fx-row fx-c-center" v-if="from === 'combo' || from === 'book'">
      <div class="btn" @click="sure">
        <span class="p">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
// type: RT表示选往返日期，其他表示只选一个日期
// d0: 可选的第一天，之前的不能选，默认是“今天”
// d1: 这天之后的不能选，默认是“一年后的前一天”
// v0: 当前选中的日期，如果类型为往返则表示入住的日期
// v1: 当前选中的退房日期，类型为往返才有效
// (以下参数表示要查询价格)
// xint: 1=国际, 0=国内
// xdep: 出发三字码
// xarr: 到达三字码
//
// 所有日期格式统一为YYYY-mm-dd

import datetime from "@/utils/datetime";
import get from "@/utils/get";
// import request from "@/request";
import { processOptions, generateMonthData } from "./helper";

import service from "@/service";

import toast from "./toasts";

export default {
  props: {
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
    from: {
      type: String,
      default: "",
    },
    productType: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      type: "", // RT:往返
      monthList: [],
      range0: null,
      range1: null,
      v0key: null,
      v1key: null,
      todayKey: null,
      priceMap: {},
      tip: "",
      priceInfos: [],
      packageId: "",
    };
  },

  computed: {
    date0() {
      if (this.v0key !== null) {
        let v0 = new Date(this._firstDay);
        v0.setDate(this.v0key / 8 + 1);
        return datetime.format("%1m月%1d日 %a", v0);
      }
      return "";
    },
    date1() {
      if (this.v1key !== null) {
        let v1 = new Date(this._firstDay);
        v1.setDate(this.v1key / 8 + 1);
        return datetime.format("%1m月%1d日 %a", v1);
      }
      return "";
    },
  },

  watch: {
    query() {
      this.v0key = null;
      this.v1key = null;
      this.init();
      // 滚动到指定日期
      if (this._options.v0) {
        this.scrollToDate(this._options.v0);
      }
    },
  },

  created() {
    this.init();
  },

  mounted() {
    if (this._options.v0) {
      this.scrollToDate(this._options.v0);
    }
  },

  methods: {
    init() {
      let query = this.query;
      let options = processOptions(query);
      let monthData = generateMonthData({
        d0: options.d0,
        d1: options.d1,
      });

      this.type = query.type || "";
      this._options = options;

      this.monthList = monthData.monthList;
      this._firstDay = monthData.firstDay;
      this._lastDay = monthData.lastDay;

      let now = new Date();
      let d = datetime.diffDay(this._firstDay, now);
      this.todayKey = d * 8;

      if (options.v0) {
        let d = datetime.diffDay(this._firstDay, options.v0);
        let v0key = d * 8;

        if (this.type === "RT" && options.v1) {
          let d = datetime.diffDay(this._firstDay, options.v1);
          let v1key = d * 8;
          // 此时显示为区域
          this.range0 = v0key;
          this.range1 = v1key;
        } else {
          // 此时显示为选中
          this.v0key = v0key;
        }
      }

      this.loadPrice();
    },
    scrollToDate(date) {
      this.$nextTick(() => {
        const id = `cal-m-${date.getFullYear()}-${date.getMonth()}`;
        const anchor = document.getElementById(id);
        const container = document.getElementById("calendar-scroll-box");

        if (anchor && container) {
          container.scrollTop = anchor.offsetTop - container.offsetTop;
        }
      });
    },

    // 重置
    reSet() {
      this.range0 = this.range1 = null;
      this.v0key = this.v1key = null;
    },

    // 点击重置，从头选择日期
    onTapReset() {
      this.reSet();
    },

    // 找到上一个日期的价格
    findPrevDay(item, index, daysIndex) {
      let _arr = [];
      if (index === 0) {
        _arr = this.monthList[index].days.slice(0, daysIndex);
      } else {
        _arr = [
          ...this.monthList[index - 1].days,
          ...this.monthList[index].days.slice(0, daysIndex),
        ];
      }

      // 过滤出可用数据
      let newArr = _arr.filter((item) => {
        return !item.blank && !item.disabled;
      });

      // 得到前一天的数据
      let prevDay = newArr.length > 1 ? newArr[newArr.length - 1] : null;
      return {
        prevDay,
        hasPrice: this.priceMap[item.k] ? true : false,
      };
    },

    onTapDay(item, index, daysIndex) {
      if (!item || item.disabled) return;

      if (this.productType === 1) {
        let prevDayData = this.findPrevDay(item, index, daysIndex);
        let { hasPrice, prevDay } = prevDayData;
        if (prevDay && this.v0key) {
          // 一晚的，有无价格都通过，多晚的无价格不通过
          if (prevDay.k !== this.v0key && !hasPrice) {
            toast.show("当天没有价格", { duration: 1000 });
            return;
          }
        }
      } else {
        if (!this.priceMap[item.k]) {
          toast.show("当天没有价格", { duration: 1000 });
          return;
        }
      }

      const k = item.k; // k = n*8

      if (this.type === "RT") {
        if (this.range0 || this.range1) {
          this.range0 = this.range1 = null;
        }

        if (this.v0key !== null) {
          if (this.v1key !== null) {
            this.reSet();
            this.v0key = k;
            toast.show("请选择退房日期", { duration: 1000 });

            // 不应该出现这种情况，如果已经选好了两个日期，那应该已经返回了
            return;
          } else if (k < this.v0key) {
            // 如果此次选中的日期比去程日期还小，则设置为去程
            this.v0key = k;
            this.loadPrice();
          } else {
            // 设置为回程
            this.v1key = k;
            this.range0 = this.v0key;
            this.range1 = this.v1key;
            this.confirm();
          }
        } else {
          this.v0key = k;
          toast.show("请选择退房日期", { duration: 1000 });

          // this.loadPrice();
        }
      } else {
        this.v0key = k;
        this.confirm();
      }
    },

    confirm() {
      if (this.from === "combo" || this.from === "book") {
        return;
      }
      setTimeout(() => {
        this._confirm();
      }, 300);
    },

    _confirm() {
      if (this.v0key === null || (this.type === "RT" && this.v1key === null)) {
        toast.show("请选择退房日期", { duration: 1000 });
        return;
      }

      let result = [];
      let v0, v1;
      v0 = new Date(this._firstDay);
      v0.setDate(this.v0key / 8 + 1);
      result.push(datetime.format("%Y-%m-%d", v0));

      if (this.type === "RT") {
        v1 = new Date(this._firstDay);
        v1.setDate(this.v1key / 8 + 1);
        result.push(datetime.format("%Y-%m-%d", v1));
      }

      if (result[0]) {
        this.priceInfos.map((item) => {
          if (item.saleDate == result[0]) {
            this.packageId = item.packageId;
          }
        });
      }
      let obj = {
        result,
        packageId: this.packageId,
      };
      this.$emit("submit", obj);
    },

    loadPrice(cb) {
      const { packageId, productId, startDate, endDate, scenicSpotId, pid } =
        this.query || {};
      // if (!xdep || !xarr) {
      //     return;
      // }

      // const data = {
      //     pid: xint === "1" ? 4204 : 4202,
      //     type: this.type === "RT" ? "RT" : "OW",
      //     scty: xdep,
      //     ecty: xarr
      // };

      // if (this.type === "RT") {
      //     // 如果只选去程日期没选回程，则请求带上去程日期
      //     if (this.v0key !== null && this.v1key === null) {
      //         let date = new Date(this._firstDay);
      //         date.setDate(this.v0key / 8 + 1);
      //         data.date = datetime.format("%Y-%m-%d", date);
      //     }
      // }
      //门票价格日历
      if (pid == "215049") {
        let params = {
          productId: productId || "",
          startDate: startDate || "",
          endDate: endDate || "",
          scenicSpotId: scenicSpotId || "",
          packageId: packageId || "",
        };
        service.r215049(params).then((r) => {
          const prices = get(r, "res.bd.data.priceInfos", []);
          this.priceInfos = prices;
          this.priceMap = prices.reduce((r, p) => {
            if (p.salePrice) {
              const d = datetime.diffDay(this._firstDay, p.saleDate);
              if (d >= 0) {
                r[d * 8] = {
                  // v: Math.ceil(p.salePrice),
                  v: p.salePrice,
                  t: p.type, // 1=低价
                };
              }
            }
            return r;
          }, {});
          cb && cb();
        });
      } else if (pid == "215042") {
        //跟团游价格日历

        let params = {
          packageId: packageId,
          startDate: startDate,
          endDate: endDate,
          productId: productId,
        };
        service.r215042(params).then((r) => {
          const prices = get(r, "res.bd.data.priceInfos", []);
          this.priceInfos = prices;
          this.priceMap = prices.reduce((r, p) => {
            if (p.salePrice) {
              const d = datetime.diffDay(this._firstDay, p.saleDate);
              if (d >= 0) {
                r[d * 8] = {
                  // v: Math.ceil(p.salePrice),
                  v: p.salePrice,
                  t: p.type, // 1=低价
                };
              }
            }
            return r;
          }, {});

          cb && cb();
        });
      } else if (pid == "215052") {
        //酒景价格日历

        let params = {
          packageId: packageId,
          startDate: startDate,
          endDate: endDate,
          productId: productId,
        };
        service.r215052(params).then((r) => {
          const prices = get(r, "res.bd.data.priceInfos", []);
          this.priceInfos = prices;
          this.priceMap = prices.reduce((r, p) => {
            if (p.salePrice) {
              const d = datetime.diffDay(this._firstDay, p.saleDate);
              if (d >= 0) {
                r[d * 8] = {
                  // v: Math.ceil(p.salePrice),
                  v: p.salePrice,
                  t: p.type, // 1=低价
                };
              }
            }
            return r;
          }, {});

          cb && cb();
        });
      }
    },

    showTip({ msg }) {
      clearTimeout(this.__tipTimer);
      this.tip = msg;
      this.__tipTimer = setTimeout(() => {
        this.tip = "";
      }, 2500);
    },

    sure() {
      this._confirm();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars";

.calendar-component {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .calendar-header {
    position: relative;
    z-index: 1;

    /* 覆盖在calendar-body上 */
    .calendar-toast {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14 * $px;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      padding: 4 * $px 8 * $px;
      border-radius: 4 * $px;
      pointer-events: none;
    }
  }

  .calendar-body {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

.cal-preview {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 12px 12px 0 12px;
  overflow: hidden;
}
.cal-preview-sep {
  height: 32px;
  width: 1px;
  background: #cccccc;
  transform: rotate(40deg);
}
.cal-preview-col {
  flex: 1;
  animation: 0.3s ease slide-up;
}
.cal-label {
  height: 17 * $px;
  font-size: 13 * $px;
  font-weight: 500;
  color: rgba(187, 187, 187, 1);
  line-height: 17 * $px;
}
.cal-label-lg {
  height: 22 * $px;
  font-size: 18 * $px;
  font-weight: 500;
  color: rgba(187, 187, 187, 1);
  line-height: 22 * $px;
}
.cal-value {
  height: 22 * $px;
  font-size: 18 * $px;
  font-weight: 500;
  color: rgba(38, 38, 38, 1);
  line-height: 22 * $px;
}
@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.weekday-label {
  background-color: #fff;
  height: 40 * $px;
  line-height: 40 * $px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  font-size: 13 * $px;
  font-weight: 400;
  text-align: center;
  color: #262626;
  box-shadow: 0 2px 10px rgba(125, 126, 128, 0.16);
}
.weekday-label__0,
weekday-label__6 {
  color: rgba(255, 150, 0, 1);
}

.month-label {
  padding-top: 24 * $px;
  padding-left: 20 * $px;
  padding-bottom: 10 * $px;
  font-weight: 500;
  font-size: 20 * $px;
  color: rgba(38, 38, 38, 1);
}

.days {
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 4 * $px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.bg-large-number {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 228 * $px;
  font-size: 190 * $px;
  font-family: Avenir-Heavy, Avenir, Sans-Serif;
  font-weight: 800;
  color: rgba(246, 246, 246, 1);
  line-height: 228 * $px;
}

.day {
  position: relative;
  z-index: 0;
  height: 56 * $px;
  margin: 2 * $px 0;
  width: 14.28%;
  overflow: hidden;
}
.day.include::before,
.day.i-open::before,
.day.i-close::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  height: 100%;
  background: rgba(17, 133, 255, 0.06);
}
.day.include::before {
  left: 0;
  right: 0;
}
.day.i-open::before {
  left: 5 * $px;
  right: 0;
  border-top-left-radius: 4 * $px;
  border-bottom-left-radius: 4 * $px;
}
.day.i-close::before {
  left: 0;
  right: 5 * $px;
  border-top-right-radius: 4 * $px;
  border-bottom-right-radius: 4 * $px;
}
.day.i-open.i-close::before {
  left: 5 * $px;
  right: 5 * $px;
  border-radius: 4 * $px;
}

.date {
  height: 100%;
  width: 46 * $px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  font-size: 13 * $px;
  line-height: 20 * $px;
  font-weight: 500;
  position: relative;
  box-sizing: border-box;

  &.active {
    border-radius: 4 * $px;
    background: #1985ff;
    color: #fff;
  }
}

.date-disabled {
  color: #bbb;
}
.date-sp__,
.date-sp__on {
  color: rgba(38, 38, 38, 1);
}
.date-sp__weekend,
.date-sp__off {
  color: rgba(255, 150, 0, 1);
}
.date.active {
  color: #fff;
}
.date-bd {
  padding-top: 18 * $px;
  font-family: Avenir-Medium, Avenir, Sans-Serif;
  font-weight: 500;
  font-size: 18 * $px;
}

.date-head {
  position: absolute;
  font-size: 10 * $px;
  font-weight: 500;
  top: 2 * $px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
  line-height: 1.4;
}
.date-foot {
  line-height: 1.4;
  font-size: 8 * $px;
  font-weight: 300;
  color: #999;
  &.low-price {
    color: #ff9600;
  }
}
.date.active .date-foot {
  color: #fff;
}
.date-head.date-head-holiday {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap;
  padding: 2 * $px;
  line-height: 1;
  background: linear-gradient(
    90deg,
    rgba(255, 150, 0, 1) 0%,
    rgba(255, 104, 20, 1) 100%
  );
  border-radius: 6px 3px 3px 0px;
  color: #fff;
  font-size: 10 * $px;
}
.date-disabled .date-head-holiday {
  background: rgba(0, 0, 0, 0.2);
}

.fn-btns {
  border-top: 1 * $px solid #f6f6f6;
  height: 64 * $px;
  background: #ffffff;
  z-index: 1;
  padding: 0 0 0 16 * $px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  .btn {
    flex: 1;
    background: linear-gradient(90deg, #36a7ff, #1985ff);
    border-radius: 4 * $px;
    line-height: 44 * $px;
    text-align: center;
    margin-right: 16 * $px;

    .p {
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      line-height: 25 * $px;
    }
  }
}
</style>
