import merge from "@/utils/merge";

import common from "./api/common";
import pages from "./api/pages";

let jipiaoReq = merge({}, common, pages);

const request = (pid) => (params) => {
  return jipiaoReq[pid](params);
};

export default {
  h5PayGetToken: request("h5PayGetToken"), // h5Pay获取身份令牌
  getTemplate: request("getTemplate"), // 获取支付摘要
  r102130: request("102130"), // NewUserInfo
  r215002: request("215002"),
  r215003: request("215003"),
  r215004: request("215004"),
  r215005: request("215005"),
  r215006: request("215006"),
  r215007: request("215007"),
  r215008: request("215008"),
  r215009: request("215009"),
  r215010: request("215010"),
  r215021: request("215021"),
  r215022: request("215022"),
  r215023: request("215023"),
  r215024: request("215024"),
  r215025: request("215025"),
  r215026: request("215026"),
  r215027: request("215027"),
  r215028: request("215028"),
  r215029: request("215029"),
  r215030: request("215030"),
  r215031: request("215031"),
  r215032: request("215032"),
  r215034: request("215034"),
  r215035: request("215035"),
  r215036: request("215036"),
  r215037: request("215037"),
  r215038: request("215038"),
  r215040: request("215040"),
  r215045: request("215045"),
  r215043: request("215043"),
  r215039: request("215039"),
  r215055: request("215055"),
  r215044: request("215044"),
  r215046: request("215046"),
  r215048: request("215048"),
  r215047: request("215047"),
  r215049: request("215049"),
  r215042: request("215042"),
  r215041: request("215041"),
  r215050: request("215050"),
  r215051: request("215051"),
  r215052: request("215052"),
  r215053: request("215053"),
  r215054: request("215054"),
  r215070: request("215070"),
  r215071: request("215071"),
  r215072: request("215072"),
  r215066: request("215066"),
  r215068: request("215068"),
  r215067: request("215067"),
  r215069: request("215069"),
  r215058: request("215058"),
  r215033: request("215033"),
};
