<template>
  <div class="swiper-part" v-if="show">
    <swiper class="swiper" width="100%" :show-indicators="false" :loop="false" :style="heightToPx">
      <swiper-item v-for="(item, index) in ads" :key="index" width="100%" :style="heightToPx">
        <img :src="item" alt="" width="100%" :style="heightToPx" />
        <div class="nums" @click="gotoPhotoList">{{ index + 1 }}/{{ ads.length }}</div>
      </swiper-item>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperItem } from "huoli-ui";
import { px2rem } from "@/projects/list/mixins";

export default {
  mixins: [px2rem],
  components: {
    Swiper,
    SwiperItem,
  },

  props: {
    ads: {
      type: Array,
      default: function () {
        return [];
      },
    },
    height: {
      type: Number,
      default: 242,
    },
    show: {
      type: Boolean,
      default: true,
    },
    axiosObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showPhotoList: {
      type: Boolean,
      default: false,
    },
    typeOfBusiness: {
      type: Number, //0：空 1:景点 2:跟团游 3:酒景
      default: 0,
    },
  },

  data() {
    return {};
  },

  created() {},
  computed: {
    heightToPx() {
      return { height: this.px2rem(this.height) };
    },
  },
  methods: {
    gotoPhotoList() {
      let query = {};
      if (this.showPhotoList && this.typeOfBusiness != 0) {
        if (this.typeOfBusiness == 1) {
          query = {
            scenicSpotId: this.axiosObj.scenicSpotId,
            typeOfBusiness: this.typeOfBusiness,
          };
        } else if (this.typeOfBusiness == 2) {
          query = {
            destinationCode: this.axiosObj.destinationCode,
            productId: this.axiosObj.productId,
            selectDate: this.axiosObj.selectDate,
            typeOfBusiness: this.typeOfBusiness,
          };
        } else if (this.typeOfBusiness == 3) {
          query = {
            productId: this.axiosObj.productId,
            typeOfBusiness: this.typeOfBusiness,
          };
        }
        // const page = { path: "/photoList", query };

        // this.$go({
        //   url: "/photoList",
        //   query: query,
        //   name: "photoList", // 要使的params可以传参，必须配置name属性，参考router配置和跳转配置。
        //   params: {},
        // });
        const page = {
          url: "/photoList",
          name: "photoList",
          query: query,
          params: {},
        };
        this.$page.push(page);
        // this.$router.push(page);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_vars.scss";
.swiper-part {
  width: 100%;
  .swiper {
    width: 100%;
    height: 242 * $px;
  }
  .pic {
    width: 100%;
    height: 100%;
  }
  ::v-deep .hl-swiper-item img {
    object-fit: cover;
  }
  .nums {
    position: absolute;
    right: 16 * $px;
    bottom: 23 * $px;
    padding: 4 * $px 8 * $px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 11 * $px;
    font-size: 12px;
    color: #ffffff;
    line-height: 17 * $px;
    z-index: 2;
  }
}
</style>
