import request from "@/lib/request";

export default {
  /*
   * 获取客户会员信息
   */
  102301: () => {
    return request.gateway({
      auth: "yes",
      url: "/gateway/api/user",
      data: { pid: 102301 },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 新个人中心
   */
  102130: () => {
    return request.gateway({
      auth: "yes",
      url: "/gateway/api/user",
      data: {
        pid: 102130,
      },
      sidFields: ["uid", "pid", "systemtime"],
    });
  },
  /*
   * 获取本人证件列表
   */
  102106: () => {
    return request.gateway({
      auth: "yes",
      url: "/gateway/api/user",
      data: {
        pid: 102106,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取城市数据
   */
  215002: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: { pid: 215002, ...params },
      sidFields: ["uid", "pid"],
    });
  },
};
