<template>
  <div class="popup a-fix-popup">
    <!-- 挂载到 #app 节点下 -->
    <hl-popup
      v-model="show"
      position="bottom"
      round
      :style="{ height: '85%', bottom: '0' }"
      closeable
    >
      <c-loading :loading="isLoading">
        <div class="price-popup fx-col">
          <div class="close" @click="close">
            <img
              src="https://cdn.133.cn/ticket/images/hangban/vue/hltrip/icon/close-3@3x.png"
            />
          </div>
          <div class="popup-top">
            <div class="title">选择日期</div>
          </div>
          <div class="popup-middle">
            <CalendarComponent
              ref="calendarRef"
              :from="from"
              :query="calendarQuery"
              :productType="productType"
              @submit="onSelectCalendarDate"
            >
            </CalendarComponent>
          </div>
        </div>
        <div class="no-data"></div>
      </c-loading>
    </hl-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// import vanPopup from "vant/lib/popup";
// import "vant/lib/popup/style";

// import auth from "@/request/auth";
// import goToUrl from '../../../../utils/goToUrl';
// import get from "@/utils/get";
// import clone from '@/utils/clone';
// import localStorage from "@/utils/localStorage";
// import env from '../../../../utils/env';

// import * as Utils from '@/projects/hltrip/lib/utils';

// import service from "@/projects/hltrip/service";
// import * as Filter from "@/projects/hltrip/lib/filter";

import CalendarComponent from "./calendar/calendar.vue";

export default {
  components: {
    // vanPopup,
    CalendarComponent,
  },

  props: {
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      // showPopup: true,
      isLoading: false,
      show: false, // 是否显示弹出框
      initQuery: {
        // type: "RT",     // 表示选择时间段
        d0: "",
        d1: "",
        v0: "",
        xint: "0",
        // xdep: q.dcode,
        // xarr: q.acode,
      },
      initSearch: {},
      from: "more-price",
      productCode: "",
      packageCode: "",
      productType: 0, // 产品类型 1：酒店+ 2：门票 3：跟团游 4:餐饮 5:门票+
    };
  },
  computed: {
    ...mapState("hltrip/global", ["startDate", "endDate"]),
    calendarQuery() {
      return Object.assign({}, this.initQuery, this.initSearch, this.query);
    },
  },
  created() {},

  activated() {
    // this.loadPriceData();
  },

  methods: {
    ...mapActions("hltrip/global", ["updateStartDate", "updateEndDate"]),

    // 初始化数据
    initData() {
      // this.loadPriceData();
    },

    // 是否显示国家选择弹出框
    open(params) {
      let { from, date, productCode, packageCode, productType } = params || {};
      this.from = from;
      this.productCode = productCode;
      this.packageCode = packageCode;
      this.productType = productType;

      // 传入价格日历参数
      this.initSearch = {
        packageCode,
        v0: date, // 选中日期
      };

      this.initData();
      this.show = true;
    },

    handleClickOverlay() {
      this.$refs.calendarRef.reSet();
    },

    close() {
      this.$refs.calendarRef.reSet();
      this.show = false;
    },

    onSelectCalendarDate(obj) {
      this.close();
      this.$emit("confirm", {
        from: this.from,
        productId: this.productId,
        packageId: obj.packageId,
        productType: this.productType,
        dateRes: obj.result,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/style";
@import "@/scss/common";

.price-popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .close {
    position: absolute;
    top: 16 * $px;
    right: 16 * $px;
    z-index: 1;

    img {
      display: block;
      width: 24 * $px;
      height: 24 * $px;
    }
  }

  .popup-top {
    height: 56 * $px;

    .title {
      line-height: 56 * $px;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: #262626;
    }
  }

  .popup-middle {
    flex: 1;
    overflow: auto;
  }
}
</style>
