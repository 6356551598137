import request from "@/lib/request";

export default {
  /*
   * 获取旅游产品列表
   */
  215003: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215003,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取旅游产品详情列表
   */
  215004: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215004,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取旅游产品酒景详情
   */
  215005: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215005,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取旅游产品套餐详情
   */
  215006: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215006,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },

  /*
   * 价格计算
   */
  215007: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215007,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取首页数据
   */
  215008: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215008,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取填单页旅游产品套餐详情
   */
  215009: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215009,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取填单页旅游产品套餐详情
   */
  215010: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215010,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取填单页旅游产品套餐详情
   */
  215021: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215021,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 取消订单接口
   */
  215022: (params) => {
    return request.gateway({
      method: "POST",
      auth: "yes",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215022,
        ...params,
      },
      sidFields: ["uid", "pid", "orderId"],
    });
  },
  /*
   * 获取订单列表
   */
  215023: (params) => {
    return request.gateway({
      method: "POST",
      auth: "yes",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215023,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取订单详情
   */
  215024: (params) => {
    return request.gateway({
      method: "POST",
      auth: "yes",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215024,
        ...params,
      },
      sidFields: ["uid", "pid", "orderId"],
    });
  },
  /*
   * 申请退款接口
   */
  215025: (params) => {
    return request.gateway({
      method: "POST",
      auth: "yes",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215025,
        ...params,
      },
      sidFields: ["uid", "pid", "orderId"],
    });
  },
  /*
   * 重发取票凭证接口
   */
  215026: (params) => {
    return request.gateway({
      method: "POST",
      auth: "yes",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215026,
        ...params,
      },
      sidFields: ["uid", "pid", "orderId"],
    });
  },
  /*
   * 订单详情申请发票接口
   */
  215027: (params) => {
    return request.gateway({
      method: "POST",
      auth: "yes",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215027,
        ...params,
      },
      sidFields: ["uid", "pid", "orderId"],
    });
  },
  /*
   * 订单套餐详情
   */
  215028: (params) => {
    return request.gateway({
      method: "POST",
      auth: "yes",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215028,
        ...params,
      },
      sidFields: ["uid", "pid", "orderId"],
    });
  },
  /*
   * 出行人列表数据
   */
  215029: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215029,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 新增或者修改出行人信息
   */
  215030: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215030,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取所有证件信息出行人信息
   */
  215031: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215031,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 删除出行人
   */
  215032: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215032,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取首页分类标签
   */
  215034: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215034,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取首页广告位
   */
  215035: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215035,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取首页根据热门城市列表
   */
  215036: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215036,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取首页产品分类
   */
  215037: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215037,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 获取首页热门城市标签
   */
  215038: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215038,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 跟团游产品列表数据接口说明
   */
  215040: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215040,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 酒景套餐产品列表数据接口说明
   */
  215045: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215045,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 门票列表
   */
  215043: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215043,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 城市数据接口
   */
  215039: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215039,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 混合列表
   */
  215055: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215055,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 门票详情之简单景点信息
   */
  215044: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215044,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 门票详情之景点详情
   */
  215046: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215046,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 门票产品列表
   */
  215047: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215047,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 门票产品详情
   */
  215048: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215048,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 门票价格日历
   */
  215049: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215049,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 跟团游价格日历
   */
  215042: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215042,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 跟团游价格日历
   */
  215041: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215041,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 酒景套餐产品详情
   */
  215050: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215050,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 酒景套餐产品套餐列表
   */
  215051: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215051,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 酒景产品价格日历
   */
  215052: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215052,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 酒景套餐产品套餐详情
   */
  215053: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215053,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 酒店详情数据
   */
  215054: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215054,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 跟团游搜索推荐位侧边栏
   */
  215070: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215070,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 跟团游搜索默认推荐
   */
  215071: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215071,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 跟团游搜索推荐
   */
  215072: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215072,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 首页和综合页搜索默认推荐
   */
  215066: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215066,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 门票搜索默认推荐
   */
  215068: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215068,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 门票搜索推荐
   */
  215069: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215069,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 首页和综合页搜索推荐
   */
  215067: (params) => {
    return request.gateway({
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215067,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 215033 电子合同手签
   */
  215033: (params) => {
    return request.gateway({
      auth: "yes",
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215033,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
  /*
   * 215058 订单页合同确认
   */
  215058: (params) => {
    return request.gateway({
      auth: "yes",
      method: "POST",
      url: "/gateway/interticket/hltripconsumer",
      data: {
        pid: 215058,
        ...params,
      },
      sidFields: ["uid", "pid"],
    });
  },
};
