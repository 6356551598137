import datetime from "@/utils/datetime";
// import request from "@/request";

export { processOptions, generateMonthData };

function processOptions(options) {
  // 如果没传入d0或d0格式错误，则d0 = today
  let d0 = null;
  if (options.d0) {
    d0 = datetime.toDate(options.d0);
  }
  if (!d0 || isNaN(d0)) {
    d0 = datetime.today();
  }

  // 如果没传入d1或d1格式错误，则d1=today+1年-1天
  let d1 = null;
  if (options.d1) {
    d1 = datetime.toDate(options.d1);
  }
  if (!d1 || isNaN(d1)) {
    // 比如今天是2000-01-23，则d1为2001-01-22
    d1 = datetime.today();
    d1.setFullYear(d1.getFullYear() + 1);
    d1.setDate(d1.getDate() - 1);
  }

  let v0 = null;
  if (options.v0) {
    v0 = datetime.toDate(options.v0);
    if (isNaN(v0)) {
      v0 = null;
    }
  }

  // 如果传入v1，v1必须大于或等于v0，否则两个值都无效
  let v1 = null;
  if (v0 && options.v1) {
    v1 = datetime.toDate(options.v1);

    let delta = -1;
    if (!isNaN(v1)) {
      delta = datetime.diffDay(v0, v1);
    }
    if (!(delta >= 0)) {
      v0 = v1 = null;
    }
  }

  return {
    d0,
    d1,
    v0,
    v1,
  };
}

// 返回从d0所在月到d1所在月的日历数据，d0之前、d1之后的不可选(disabled)
function generateMonthData({ d0, d1 }) {
  let firstDay = new Date(d0);
  firstDay.setDate(1);
  let lastDay = new Date(d1);
  lastDay.setDate(32); // 到下个月
  lastDay.setDate(0); // 再回到上个月最后一天
  let valid = (d) => {
    // 可选区间是[d0, d1]
    return d >= d0 && d <= d1;
  };

  let r = [];
  let m = null;
  let d = new Date(firstDay);
  // 每一项的key，递增，包括空格，空格+1，非空格+8，这样每个日期之间都相差8，就可以直接用天数差*8计算出key
  // 开始的第一天为0
  let k = 0;
  let _year, _month, _date, _day;

  let todayT = datetime.today().getTime();

  while (d <= lastDay) {
    _year = d.getFullYear();
    _month = d.getMonth();
    _date = d.getDate();
    _day = d.getDay();

    if (_date === 1) {
      let j = 0;
      let days = [];

      // X月的第一天
      // 补齐这个月开头的空
      let kb = k - _day;
      while (j++ < _day) {
        days.push({
          k: kb++,
          blank: true,
        });
      }

      // 补齐上个月末尾的空
      kb = k - 8 + 1;
      if (m && _day > 0) {
        while (j++ <= 7) {
          m.days.push({
            k: kb++,
            blank: true,
          });
        }
      }

      m = {
        id: _year + "-" + _month,
        y: _year,
        m: _month + 1,
        text: _year + "年" + (_month + 1) + "月",
        days: days,
      };
      r.push(m);
    }

    let holiday = getHolidayData(_year, _month + 1, _date) || {};
    let x = {
      k,
      d: _date,
      disabled: !valid(d),
    };

    if (holiday.work === 1) {
      x.sp = "on";
    } else if (holiday.work === 2) {
      x.sp = "off";
    } else if (_day === 0 || _day === 6) {
      x.sp = "weekend";
    } else {
      x.sp = "";
    }

    // 显示优先级：去程/回程 > 今天 > 节日 > 班/休
    if (d.getTime() === todayT) {
      x.hdt = "今天";
    } else if (holiday.name) {
      x.hdt = holiday.name;
      x.hdc = "date-head-holiday";
    } else if (holiday.work === 1) {
      x.hdt = "班";
    } else if (holiday.work === 2) {
      x.hdt = "休";
    }

    m.days.push(x);

    // 下一天
    d.setDate(_date + 1);
    k += 8;
  }

  return {
    firstDay,
    lastDay,
    monthList: r,
  };
}

let HolidayData = null;
let DefaultHolidayData = {
  d0101: [0, "元旦"],
  d0214: [0, "情人节"],
  d0308: [0, "妇女节"],
  d0501: [0, "劳动节"],
  d0601: [0, "儿童节"],
  d0910: [0, "教师节"],
  d1001: [0, "国庆节"],
  d1225: [0, "圣诞节"],
};

function getHolidayData(y, m, d) {
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }

  let data = HolidayData ? HolidayData["y" + y] : null;

  if (data) {
    data = data["d" + m + d];
  }

  if (!data) {
    data = DefaultHolidayData["d" + m + d];
  }

  if (data) {
    return {
      work: data[0],
      name: data[1],
    };
  } else {
    return null;
  }
}

// function initHolidayData() {
//   request
//     .h5({
//       url: "/hangban/data/holiday",
//     })
//     .then((r) => {
//       if (r) {
//         HolidayData = r;
//       }
//     });
// }
// initHolidayData();
