import "./toasts.scss";
var toastsTimer = null;
function showToasts(msg, { duration } = {}) {
  var el = document.querySelector(".hl-toasts");
  var body = document.querySelector("body");
  if (!el) {
    el = document.createElement("div");
    el.className = "hl-toasts";
    body.appendChild(el);
  }
  el.innerHTML = msg;
  el.style.display = "block";
  el.style["margin-left"] = -(el.clientWidth / 2) + "px";
  el.classList.add("show");
  if (toastsTimer) {
    clearTimeout(toastsTimer);
  }
  toastsTimer = setTimeout(function () {
    el.classList.remove("show");
    toastsTimer = setTimeout(function () {
      toastsTimer = null;
      el.style.display = "none";
    }, 1000);
  }, duration || 3000);
}
export default {
  show: showToasts,
};
